import { useRef } from 'react';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { DSDescription, DSTitle } from '../styles/shared-styles';
import { useInViewport } from 'react-in-viewport';
import { PieChart } from 'react-minimal-pie-chart';
import { TokenDistributionTable } from './token-distribution-table';
import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from '@heroicons/react/solid';

const data = [
  { title: 'Seed', value: 7.5, color: '#374E60' },
  { title: 'Private A', value: 7.5, color: '#446073' },
  { title: 'Pre Public', value: 7.5, color: '#517185' },
  { title: 'Public', value: 7.5, color: '#658CA2' },
  { title: 'Marketing', value: 15, color: '#6366F1' },
  { title: 'Reserve', value: 10, color: '#FFBF40' },
  { title: 'Liquidity & Exchanges', value: 15, color: '#F30072' },
  { title: 'Advisors', value: 5, color: '#A6E4FF' },
  { title: 'Team', value: 25, color: '#00B1FF' },
];

const Container = styled.div`
  ${tw`flex flex-col items-center pt-20 pb-20 md:pb-40`}
`;

export const TokenDistribution = () => {
  const myRef = useRef();
  const options = {};
  const config = { disconnectOnLeave: false };
  const props = {};

  const { inViewport } = useInViewport(myRef, options, config, props);
  const { formatMessage } = useIntl();

  let interval;

  const scrollLeft = (e) => {
    e.preventDefault();

    interval = setInterval(
      () => document.getElementById('tdt-scroll').scrollBy(-6, 0),
      10
    );
  };

  const scrollRight = (e) => {
    e.preventDefault();

    interval = setInterval(
      () => document.getElementById('tdt-scroll').scrollBy(6, 0),
      10
    );
  };

  return (
    <Container id="token-distribution">
      <div tw="relative w-full flex flex-col items-center lg:(flex-row items-start justify-between)">
        <div tw="max-width[421px] mt-8 md:mt-32 lg:mb-80">
          <DSTitle tw="mb-4">
            {formatMessage({ id: 'tokenDistribution.title' })}
          </DSTitle>
          <DSDescription>
            {formatMessage({ id: 'tokenDistribution.description' })}
          </DSDescription>
          <DSDescription tw="mt-14 text-base">
            {formatMessage({ id: 'tokenDistribution.details' })}
            <span tw="color[#6366F1] underline font-semibold">
              <a
                href="https://docs.google.com/spreadsheets/d/1SdM4CqRPQC_1rC7XBdNeJWtNxFc_p9yPZ-YkwqzWEr0/edit?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                {formatMessage({ id: 'tokenDistribution.details2' })}
              </a>
            </span>
          </DSDescription>
        </div>
        <div
          ref={myRef}
          tw="flex flex-shrink w-full h-full mb-16 mt-6 min-height[385px] md:(min-height[744px]) lg:(max-width[544px] max-height[544px] mt-12) xl:(max-width[644px] max-height[644px] mt-10)"
        >
          {inViewport ? (
            <PieChart
              data={data}
              radius={PieChart.defaultProps.radius - 20}
              label={({ dataEntry }) =>
                `${dataEntry.title} ${dataEntry.value}%`
              }
              labelStyle={{
                fill: 'white',
                fontSize: '1.5px',
              }}
              labelPosition={120}
              animate
              animationDuration={850}
              animationEasing="ease-in-out"
            />
          ) : null}
        </div>
        <div tw="absolute flex items-center bottom-5 left-0 text-white text-sm md:text-base 2xl:hidden">
          <ArrowCircleLeftIcon
            tw="text-blue w-8 mr-2 cursor-pointer hover:opacity-70"
            onMouseDown={(e) => scrollLeft(e)}
            onMouseUp={() => clearInterval(interval)}
          />
          {formatMessage({ id: 'roadmap.scroll' })}
          <ArrowCircleRightIcon
            tw="text-blue w-8 ml-2 cursor-pointer hover:opacity-70"
            onMouseDown={(e) => scrollRight(e)}
            onMouseUp={() => clearInterval(interval)}
          />
        </div>
      </div>
      <TokenDistributionTable />
    </Container>
  );
};
