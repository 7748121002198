import tw, { styled } from 'twin.macro';

const BoldBlue = styled.td`
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #a6e4ff;
  text-align: center;
  border: 1px solid #a6e4ff;
`;

const BoldWhite = styled.td`
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: white;
  text-align: center;
  border: 1px solid #a6e4ff;
`;

const RegularWhite = styled.td`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: white;
  text-align: center;
  border: 1px solid #a6e4ff26;
  background: rgba(255, 255, 255, 0.05);
`;

export const TokenDistributionTable = () => {
  return (
    <div
      id="tdt-scroll"
      className="tdt"
      tw="w-full max-width[1536px] border[2px solid #A6E4FF] border-radius[16px] overflow-auto"
    >
      <table tw="w-full">
        <thead tw="background[#a6e4ff] border-right-width[1px] borer-right-color[#a6e4ff]">
          <tr>
            <th tw="border-color[#A6E4FF]"></th>
            <th></th>
            <th></th>
            <th tw="font-semibold">Token Distribution</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <BoldBlue tw="background[#a6e4ff40]">Pool</BoldBlue>
            <BoldBlue tw="background[#a6e4ff40]">%</BoldBlue>
            <BoldBlue tw="background[#a6e4ff40]">TGE</BoldBlue>
            <BoldBlue tw="background[#a6e4ff40]">Cliff</BoldBlue>
            <BoldBlue tw="background[#a6e4ff40]">Vesting</BoldBlue>
            <BoldBlue tw="background[#a6e4ff40]">Amount</BoldBlue>
            <BoldBlue tw="background[#a6e4ff40]">Price</BoldBlue>
          </tr>
          <tr>
            <BoldBlue tw="background[rgba(255, 255, 255, 0.05)]">Team</BoldBlue>
            <RegularWhite>25%</RegularWhite>
            <RegularWhite>-</RegularWhite>
            <RegularWhite>12 months</RegularWhite>
            <RegularWhite>Linearly in 15 months</RegularWhite>
            <RegularWhite>2,500,000,000</RegularWhite>
            <RegularWhite>-</RegularWhite>
          </tr>
          <tr>
            <BoldBlue tw="background[rgba(255, 255, 255, 0.05)]">
              Advisors
            </BoldBlue>
            <RegularWhite>5%</RegularWhite>
            <RegularWhite>-</RegularWhite>
            <RegularWhite>32 months</RegularWhite>
            <RegularWhite>Linearly in 15 months</RegularWhite>
            <RegularWhite>500,000,000</RegularWhite>
            <RegularWhite>-</RegularWhite>
          </tr>
          <tr>
            <BoldBlue tw="background[rgba(255, 255, 255, 0.05)]">
              Liquidity & Exchanges
            </BoldBlue>
            <RegularWhite>15%</RegularWhite>
            <RegularWhite>50%</RegularWhite>
            <RegularWhite>5 months</RegularWhite>
            <RegularWhite>Remaining 10% per 1 month</RegularWhite>
            <RegularWhite>1,000,000,000</RegularWhite>
            <RegularWhite>-</RegularWhite>
          </tr>
          <tr>
            <BoldBlue tw="background[rgba(255, 255, 255, 0.05)]">
              Marketing
            </BoldBlue>
            <RegularWhite>15%</RegularWhite>
            <RegularWhite>10%</RegularWhite>
            <RegularWhite>12 months</RegularWhite>
            <RegularWhite>Remaining 10% per 1 month</RegularWhite>
            <RegularWhite>1,500,000,000</RegularWhite>
            <RegularWhite>-</RegularWhite>
          </tr>
          <tr>
            <BoldBlue tw="border-b-0 background[rgba(255, 255, 255, 0.05)]">
              Reserve
            </BoldBlue>
            <RegularWhite>10%</RegularWhite>
            <RegularWhite>10%</RegularWhite>
            <RegularWhite>12 months</RegularWhite>
            <RegularWhite>Remaining 10% per 1 month</RegularWhite>
            <RegularWhite>1,000,000,000</RegularWhite>
            <RegularWhite>-</RegularWhite>
          </tr>
          <tr tw="background[#a6e4ff] border-right-width[1px] borer-right-color[#a6e4ff]">
            <th></th>
            <th></th>
            <th></th>
            <th tw="font-semibold">Rounds</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <BoldBlue tw="background[rgba(255, 255, 255, 0.05)]">Seed</BoldBlue>
            <RegularWhite>7.5%</RegularWhite>
            <RegularWhite>10%</RegularWhite>
            <RegularWhite>-</RegularWhite>
            <RegularWhite>
              Compounding
              <br />
              5% per 1 month
              <br />
              distributed <strong>weekly</strong>
            </RegularWhite>
            <RegularWhite>750,000,000</RegularWhite>
            <RegularWhite>$ 0.00057</RegularWhite>
          </tr>
          <tr>
            <BoldBlue tw="background[rgba(255, 255, 255, 0.05)]">
              Private A
            </BoldBlue>
            <RegularWhite>7.5%</RegularWhite>
            <RegularWhite>5%</RegularWhite>
            <RegularWhite>-</RegularWhite>
            <RegularWhite>
              Compounding
              <br />
              5% per 1 month
              <br />
              distributed <strong>daily</strong>
            </RegularWhite>
            <RegularWhite>750,000,000</RegularWhite>
            <RegularWhite>$ 0.00096</RegularWhite>
          </tr>
          <tr>
            <BoldBlue tw="background[rgba(255, 255, 255, 0.05)]">
              Pre Public
            </BoldBlue>
            <RegularWhite>7.5%</RegularWhite>
            <RegularWhite>5%</RegularWhite>
            <RegularWhite>-</RegularWhite>
            <RegularWhite>
              Compounding
              <br />
              5% per 1 month
              <br />
              distributed <strong>weekly</strong>
            </RegularWhite>
            <RegularWhite>750,000,000</RegularWhite>
            <RegularWhite>$ 0.00147</RegularWhite>
          </tr>
          <tr>
            <BoldBlue tw="background[rgba(255, 255, 255, 0.05)]">
              Public
            </BoldBlue>
            <RegularWhite tw="border-bottom-color[#A6E4FF]">7.5%</RegularWhite>
            <RegularWhite tw="border-bottom-color[#A6E4FF]">100%</RegularWhite>
            <RegularWhite tw="border-bottom-color[#A6E4FF]">-</RegularWhite>
            <RegularWhite tw="border-bottom-color[#A6E4FF]">-</RegularWhite>
            <RegularWhite tw="border-bottom-color[#A6E4FF]">
              750,000,000
            </RegularWhite>
            <RegularWhite tw="border-bottom-color[#A6E4FF]">
              $ 0.00209
            </RegularWhite>
          </tr>
          <tr tw="background[#a6e4ff40]">
            <BoldBlue>Total Rounds</BoldBlue>
            <td></td>
            <td></td>

            <BoldWhite tw="border-none">
              <p tw="transform translate-x--24 text-left">
                $BIDA 3,000,000,000
              </p>
            </BoldWhite>
            <td></td>
            <td tw="border-left-width[1px] border-left-color[#A6E4FF]"></td>
            <BoldWhite tw="transform translate-x--16 border-none text-left">
              <p tw="transform translate-x-5 text-left">$3.8M</p>
            </BoldWhite>
          </tr>
          <tr tw="background[#a6e4ff40] border-bottom-width[1px] border-bottom-color[#A6E4FF]">
            <BoldWhite tw="border-b-0">FDV</BoldWhite>
            <td tw="border-t border-l-0 border-r-0 border-b-0 border-color[#A6E4FF]"></td>
            <td tw="border-t border-l-0 border-r-0 border-b-0 border-color[#A6E4FF]"></td>
            <BoldWhite tw="border-l-0 border-r-0 border-b-0 border-color[#A6E4FF] transform translate-x--24 text-left">
              $BIDA 10,000,000,000
            </BoldWhite>
            <td tw="border-t border-l-0 border-r-0 border-b-0 border-color[#A6E4FF]"></td>
            <td tw="border-t border-l-0 border-r-0 border-b-0 border-color[#A6E4FF] border-left-width[1px] border-left-color[#A6E4FF]"></td>
            <BoldWhite tw="border-t border-l-0 border-r-0 border-b-0 border-color[#A6E4FF] transform translate-x--14 text-left">
              $20.93M
            </BoldWhite>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
