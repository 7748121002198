import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

export interface ImgGQLProps {
  alt?: string;
  filename?: string;
  fluid?: any;
  className?: string;
}

export const ImgGQL = ({ filename, alt, fluid = {}, ...rest }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(
          filter: { extension: { regex: "/(jpg)|(png)|(jpeg)|(svg)/" } }
        ) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const extension = filename.match(/[^\\]*\.(\w+)$/)[1];
      if (extension === 'svg') {
        return <img alt={alt} src={filename} {...rest} />;
      }
      // Finds your image among all
      const image = data.images.edges.find((n) => {
        return n.node.relativePath.includes(filename);
      });

      if (!image) {
        return null;
      }
      return (
        <Img
          alt={alt}
          fluid={{
            ...image.node.childImageSharp.fluid,
          }}
          {...rest}
        />
      );
    }}
  />
);
