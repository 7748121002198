import { Description, SubtitleICO } from '../styles/shared-styles';
import { useIntl } from 'gatsby-plugin-react-intl';

export const Tokenomics = () => {
  const { formatMessage } = useIntl();

  return (
    <div tw="w-full bg-[#364453]" id="token-economy">
      <div tw="container mx-auto px-5 pt-28 pb-24 md:(pt-32 pb-24) lg:(pt-48 pb-40) flex flex-col ">
        <SubtitleICO tw="text-white">
          <span tw="color[#A6E4FF]">
            {formatMessage({ id: 'tokenomics.title' })}
          </span>
          {formatMessage({ id: 'tokenomics.title2' })}
        </SubtitleICO>
        <Description tw="text-white max-width[600px]">
          {formatMessage({ id: 'tokenomics.description' })}
        </Description>
      </div>
    </div>
  );
};
