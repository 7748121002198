import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Description, SubtitleICO, TitleICO } from '../styles/shared-styles';

const Container = styled.div`
  ${tw`container flex flex-col w-full items-center px-5 xl:px-3 max-width[1163px]`}
`;

export const H2 = styled.h2`
  ${tw`pt-3 pb-3 text-lg md:text-2xl flex-shrink`}
  font-weight: 700;
`;

export const P = styled.p`
  ${tw`text-medium text-base md:text-xl`}
  font-weight: 400;
`;

export const ProductHeader = () => {
  const { formatMessage, locale } = useIntl();

  return (
    <Container>
      <div tw="flex flex-col lg:w-3/4 items-center text-center" id="use-case">
        <TitleICO>{formatMessage({ id: 'product.title' })}</TitleICO>
        <div tw="flex flex-col lg:ml-0 md:grid md:grid-flow-col justify-center">
          <SubtitleICO>
            {formatMessage({ id: 'product.subtitle' })}
            <span tw="text-grey">
              {formatMessage({ id: 'product.subtitle2' })}
            </span>
            .
          </SubtitleICO>
        </div>

        {locale === 'pl' ? (
          <Description tw="text-center">
            {formatMessage({ id: 'product.description' })}
            <span tw="font-semibold">
              {formatMessage({ id: 'product.description2' })}
            </span>
            .
          </Description>
        ) : (
          <Description tw="text-center">
            {formatMessage({ id: 'product.description' })}{' '}
            <span tw="hidden sm:block"> </span>
            {formatMessage({ id: 'product.description2' })}{' '}
            <span tw="hidden sm:block"> </span>
            {formatMessage({ id: 'product.description3' })}
            <span tw="font-semibold">
              {formatMessage({ id: 'product.description4' })}
            </span>
            {formatMessage({ id: 'product.description5' })}
          </Description>
        )}
      </div>
    </Container>
  );
};
