import tw, { styled } from 'twin.macro';
import { TokenUse } from '../styles/icons';
import { DSDescription, DSTitle } from '../styles/shared-styles';
import { useIntl } from 'gatsby-plugin-react-intl';

const Container = styled.div`
  ${tw`relative w-full flex flex-col justify-center py-20 md:py-40 border-bottom-width[1px] border-bottom-color[rgba(255, 255, 255, 0.15)]`}
`;

export const UseOfToken = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <div tw="mx-auto lg:(absolute text-left) top-40 left-0 max-width[421px]">
        <DSTitle tw="mb-4">{formatMessage({ id: 'tokenUse.title' })}</DSTitle>
        <DSDescription tw="text-left">
          {formatMessage({ id: 'tokenUse.description' })}
        </DSDescription>
      </div>
      <TokenUse tw="w-full h-auto max-width[1536px] mt-20 lg:mt-0" />
    </Container>
  );
};
