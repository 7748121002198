import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Description, TitleICO } from '../styles/shared-styles';
import { ReservationSVG } from '../styles/icons';
import Img from 'gatsby-image';

const Container = styled.div`
  ${tw`flex flex-col relative items-center text-center lg:text-left py-0 lg:py-52 px-5`}
  &:after {
    content: '';
    position: absolute;
    bottom: 24%;
    left: 0;
    z-index: -1;
    width: 77%;
    height: 65%;
    background: linear-gradient(rgba(0, 19, 38, 0.03), rgba(0, 19, 38, 0.03));
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;

    @media (max-width: 1023px) {
      width: 79%;
      height: 83%;
    }

    @media (max-width: 767px) {
      width: 0;
      height: 0;
    }
  }
`;

export const Reservations = () => {
  const { formatMessage } = useIntl();

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "booking.png" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid(quality: 100, maxWidth: 1536) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container id="reservations">
      <div tw="flex flex-col lg:(width[45%] ml-12) lg:items-start xl:mt-10">
        <div tw="flex flex-row items-center justify-start md:justify-center lg:justify-start md:ml--80 lg:ml-0">
          <ReservationSVG />
          <TitleICO tw="normal-case text-black font-bold text-2xl md:text-3xl pb-0 ml-6">
            {formatMessage({ id: 'product.reservations.title' })}
          </TitleICO>
        </div>

        <Description tw="text-medium font-normal text-base max-width[660px] md:(max-width[480px] ml--20) lg:(max-width[660px] ml-0) text-left">
          {formatMessage({ id: 'product.reservations.description' })}
          <span tw="font-semibold">
            {formatMessage({ id: 'product.reservations.description2' })}
          </span>
          {formatMessage({ id: 'product.reservations.description3' })}
          <span tw="font-semibold">
            {formatMessage({ id: 'product.reservations.description4' })}
          </span>{' '}
          &
          <span tw="font-semibold">
            {formatMessage({ id: 'product.reservations.description5' })}
          </span>
          {formatMessage({ id: 'product.reservations.description6' })}
        </Description>
      </div>
      <Img
        fluid={data?.file?.childImageSharp?.fluid}
        alt="app look"
        tw="w-full max-width[1392px] mt-10"
      />
    </Container>
  );
};
