import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Description, SubtitleICO, TitleICO } from '../styles/shared-styles';
import { IGIcon, LNIcon, TWIcon } from '../styles/icons';
import { css } from '@emotion/react';
import { ImgGQL } from '../elements/image';

const Container = styled.div`
  ${tw`py-20 pt-32 md:py-40 mb--80`}
`;

const H1 = styled.h1`
  ${tw`text-white text-lg`}
  font-weight: 600;
`;

const P = styled.p`
  ${tw`text-sm text-white`}
  font-weight: 500;
`;

export interface MemberProps {
  name: string;
  title: string;
  photo: string;
  // socials?: { type: 'instagram' | 'li' | 'twitter'; link: string }[];
  socials: { insta?: string; twitter?: string; li?: string };
}

export const Member = ({
  name,
  title,
  photo,
  socials,
  ...props
}: MemberProps) => {
  return (
    <div
      tw="rounded-3xl overflow-hidden relative flex flex-col justify-between"
      css={css`
        width: 248px;
        height: 360px;
        .gatsby-image-wrapper {
          position: absolute !important;
          width: 248px;
          height: 360px;
        }
      `}
      {...props}
    >
      <ImgGQL filename={photo} alt={`${name} team member`} />
      <div
        tw="width[248px] height[360px] absolute z-20 background[linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 71, 102, 0.65) 100%)]"
        style={{
          background:
            name === 'Konrad' &&
            'linear-gradient(180deg, rgba(0, 0, 0, 0.1618) 0%, rgba(0, 71, 102, 0.65) 100%)',
        }}
      />
      <div tw="flex items-end flex-col pt-4 pr-2 z-30">
        {socials.insta && (
          <a href={socials.insta} target="_blank" rel="noreferrer">
            <IGIcon tw="opacity-75 hover:opacity-50 m-1" />
          </a>
        )}

        {socials.twitter && (
          <a href={socials.twitter} target="_blank" rel="noreferrer">
            <TWIcon tw="opacity-75 hover:opacity-50 m-1" />
          </a>
        )}

        {socials.li && (
          <a href={socials.li} target="_blank" rel="noreferrer">
            <LNIcon tw="opacity-75 hover:opacity-50 m-1" />
          </a>
        )}
      </div>
      <div tw="pl-6 pb-6 mt-auto text-white text-left relative z-30">
        <H1>{name}</H1>
        <P>{title}</P>
      </div>
    </div>
  );
};

export const Team = () => {
  const { formatMessage } = useIntl();
  return (
    <Container id="team">
      <TitleICO tw="text-center">
        {formatMessage({ id: 'team.title' })}
      </TitleICO>
      <SubtitleICO tw="text-center">
        {formatMessage({ id: 'team.subtitle' })}
        <span tw="text-grey">{formatMessage({ id: 'team.subtitle2' })}</span>.
      </SubtitleICO>
      <div tw="pt-20 md:(pt-40)">
        <div
          tw="grid grid-cols-1 gap-16 place-items-center md:(grid-cols-2) lg:(grid-cols-4 grid justify-center w-full) mx-auto"
          css={css`
            max-width: calc(4 * 248px + 4 * 64px);

            @media (min-width: 1024px) and (max-width: 1200px) {
              grid-gap: 10px;
            }
          `}
        >
          <Member
            name="Patrick Tluszcz"
            title="CEO & Founder"
            photo={'patrick.png'}
            socials={{
              li: 'https://www.linkedin.com/in/patrick-tluszcz-468b8211b/',
              twitter: 'https://twitter.com/my_personal_bra',
            }}
          />

          <Member
            name="Konrad"
            title="Chief Technology Officer"
            photo={'konrad.jpg'}
            socials={{ li: 'https://www.linkedin.com/in/konrad-hidden/' }}
          />

          <Member
            name="Max Kościsz"
            title="Chief Product Officer"
            photo={'max.png'}
            socials={{
              li: 'https://www.linkedin.com/in/max-ko%C5%9Bcisz-51b083152/',
            }}
          />

          <Member
            name="Aleksandra Kopeć"
            title="Chief Legal Officer"
            photo={'ola.png'}
            socials={{
              li: 'https://www.linkedin.com/in/aleksandra-kope%C4%87-284ab5148/',
              twitter: 'https://twitter.com/aleksandrak0pec',
              insta: 'https://www.instagram.com/finlegaltech.pl/',
            }}
          />
        </div>

        <div
          tw="pt-16 mx-auto grid grid-cols-1 gap-16 place-items-center md:(grid-cols-2) lg:(grid-cols-3 grid gap-16 justify-center w-full)"
          css={{ maxWidth: 3 * 248 + 3 * 64 }}
        >
          <Member
            name="Mateusz Biernat"
            title="Front End Developer"
            photo={'mateusz.png'}
            socials={{
              li: 'https://www.linkedin.com/in/mateusz-biernat-253006212/',
            }}
          />
          <Member
            name="Eliza Zdrojewska"
            title="Head of Marketing"
            photo={'eliza.png'}
            socials={{
              li: 'https://www.linkedin.com/in/eliza-zdrojewska/',
              insta: 'https://www.instagram.com/elizaattheuni/',
            }}
          />
          <Member
            name="Sławomir Sawicki"
            title="Growth Specialist"
            tw="md:(col-span-full mx-auto) lg:(col-auto)"
            photo={'slawomir.png'}
            socials={{ li: 'https://www.linkedin.com/in/slawomir-sawicki/' }}
          />
        </div>
      </div>
    </Container>
  );
};
