import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Description, TitleICO } from '../styles/shared-styles';
import { AuctionSVG } from '../styles/icons';
import Img from 'gatsby-image';

const Container = styled.div`
  ${tw`flex flex-col relative items-center text-center lg:text-left py-32 md:py-52 px-5`}
  &:after {
    content: '';
    position: absolute;
    bottom: 30%;
    right: 0;
    z-index: -1;
    width: 75%;
    height: 62%;
    background: linear-gradient(rgba(0, 19, 38, 0.03), rgba(0, 19, 38, 0.03));
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    @media (max-width: 1024px) {
      height: 55%;
    }

    @media (max-width: 767px) {
      width: 0;
      height: 0;
    }
  }
`;

export const Auctions = () => {
  const { formatMessage } = useIntl();

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "bidding.png" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid(quality: 100, maxWidth: 1536) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container id="auctions">
      <div tw="flex flex-col lg:(width[45%] ml-12) lg:items-start lg:mt-8 xl:mt--4">
        <div tw="flex flex-row items-center justify-start md:justify-center lg:justify-start md:ml-32 lg:ml-0">
          <AuctionSVG />
          <TitleICO tw="normal-case text-black text-left font-bold text-2xl md:text-3xl pb-0 ml-6">
            {formatMessage({ id: 'product.auctions.title' })}
            <br tw="sm:hidden" />
            {formatMessage({ id: 'product.auctions.title2' })}
          </TitleICO>
        </div>

        <Description tw="text-medium font-normal text-base max-width[660px] md:(max-width[480px] ml-44) lg:(max-width[660px] ml-0) text-left">
          {formatMessage({ id: 'product.auctions.description' })}
          <span tw="font-semibold">
            {formatMessage({ id: 'product.auctions.description2' })}
          </span>
        </Description>
      </div>
      <Img
        fluid={data?.file?.childImageSharp?.fluid}
        alt="app look"
        tw="w-full mt-5 max-width[1392px]"
      />
    </Container>
  );
};
