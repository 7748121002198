import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import ReactDOM from 'react-dom';
import tw, { styled } from 'twin.macro';
import { XIcon } from '@heroicons/react/solid';

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
`;

const Container = styled.div`
  ${tw`flex flex-col justify-between relative bg-dark p-5 pt-3`}
  border-radius: 20px;
`;

const ModalHeader = styled.div`
  ${tw`flex text-xl`}
`;

const ModalBody = styled.div`
  ${tw`grid grid-flow-row mt-8 mb-0`}
`;

const ModalCancel = styled.button`
  ${tw`absolute top-3 right-5 text-white`}
`;

export const YoutubeModal = ({ show, onClose }) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const { locale } = useIntl();

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  const handleCloseClick = () => {
    onClose();
    document.body.classList.remove('stop-scrolling');
  };

  const modalContent = show ? (
    <ModalOverlay>
      <Container>
        <ModalHeader>
          <ModalCancel>
            <XIcon tw="w-5 h-5 hover:opacity-80" onClick={handleCloseClick} />
          </ModalCancel>
        </ModalHeader>
        <ModalBody>
          <iframe
            src={`https://www.youtube.com/embed/${
              locale === 'pl' ? 'W-WjK2Gsf3E' : '1a3xFsuXhL8'
            }`}
            tw="width[768px] height[480px] border-radius[20px]"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </ModalBody>
      </Container>
    </ModalOverlay>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById('portal')
    );
  } else {
    return null;
  }
};
