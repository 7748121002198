import tw, { styled } from 'twin.macro';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Description, TitleICO } from '../styles/shared-styles';
import { Career, InvestSVG } from '../styles/icons';
import Img from 'gatsby-image';

const Container = styled.div`
  ${tw`flex flex-col relative items-center text-center lg:text-left py-32 md:(py-52 pb-28) lg:pb-52 px-5`}
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 75%;
    height: 87%;
    background: linear-gradient(rgba(0, 19, 38, 0.03), rgba(0, 19, 38, 0.03));
    border-top-left-radius: 20px;

    @media (max-width: 1024px) {
      width: 76%;
    }

    @media (max-width: 767px) {
      width: 0;
      height: 0;
    }
  }
`;

export const Invest = () => {
  const { formatMessage } = useIntl();

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "calendar.png" }) {
        childImageSharp {
          # Specify a fluid image and fragment
          # The default maxWidth is 800 pixels
          fluid(quality: 100, maxWidth: 1536) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Container id="invest">
      <div tw="flex flex-col lg:(width[45%] ml-12)">
        <div tw="flex flex-row items-center justify-start md:justify-center lg:justify-start lg:mt--2 xl:mt-8">
          <InvestSVG />
          <TitleICO tw="md:hidden normal-case text-black text-left font-bold text-2xl pb-0 ml-6">
            {formatMessage({ id: 'product.invest.title' }).split(' ')[0]}
            <br />
            {formatMessage({ id: 'product.invest.title' }).split(' ')[1]}{' '}
            {formatMessage({ id: 'product.invest.title' }).split(' ')[2]}
          </TitleICO>
          <TitleICO tw="hidden md:block normal-case text-black font-bold text-3xl pb-0 ml-6">
            {formatMessage({ id: 'product.invest.title' })}
          </TitleICO>
        </div>

        <Description tw="text-medium font-normal text-base max-width[660px] md:(max-width[480px] ml-40) lg:(max-width[660px] ml-0)  text-left">
          {formatMessage({ id: 'product.invest.description' })}
          <span tw="font-semibold">
            {formatMessage({ id: 'product.invest.description2' })}
          </span>
          {formatMessage({ id: 'product.invest.description3' })}
        </Description>
      </div>
      <div tw="flex flex-col lg:flex-row w-full items-center justify-center mt-5">
        <Img
          fluid={data?.file?.childImageSharp?.fluid}
          alt="app look"
          tw="w-full mt-5 lg:max-width[560px] xl:max-width[760px] 2xl:max-width[980px]"
        />
        <Career tw="hidden w-full mb-10 max-width[400px] lg:(flex mb-0 max-width[312px]) xl:(max-width[460px])" />
      </div>
    </Container>
  );
};
