import { BTCP2P } from '../styles/icons';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Description, SubtitleICO, TitleICO } from '../styles/shared-styles';

const Container = styled.div`
  ${tw`flex flex-col w-full relative xl:px-3`}
  ${tw`md:mt-16`}
  ${tw`lg:flex-row lg:items-center lg:justify-between`}
`;

export const Decentralize = () => {
  const { formatMessage, locale } = useIntl();

  return (
    <Container
      tw="flex flex-col justify-center items-center mt-10 pb-20 md:(mt-0 pb-28 pt-20) lg:(w-11/12 mx-auto py-40 pb-32) xl:w-full xl:py-60 z-10"
      id="decentralize"
    >
      <div tw="flex flex-col justify-center mt-10 lg:(px-0 mr-5 items-start w-1/2) px-5 lg:max-w-[600px]">
        <TitleICO>{formatMessage({ id: 'decentralize.title' })}</TitleICO>
        <div tw="flex flex-col lg:flex-row">
          {locale === 'pl' ? (
            <SubtitleICO tw="pr-3">
              {formatMessage({ id: 'decentralize.subtitle' })}
              <span tw="text-grey">
                {formatMessage({ id: 'decentralize.subtitle2' })}
                <span tw="text-black">{'.'}</span>
              </span>
            </SubtitleICO>
          ) : (
            <SubtitleICO tw="pr-3">
              {formatMessage({ id: 'decentralize.subtitle' })}
              <span tw="text-grey">
                {formatMessage({ id: 'decentralize.subtitle2' })}
              </span>
              {formatMessage({ id: 'decentralize.subtitle3' })}
              <span tw="text-grey">
                {formatMessage({ id: 'decentralize.subtitle4' })}
                <span tw="text-black">{'.'}</span>
              </span>
            </SubtitleICO>
          )}
        </div>

        <Description tw="text-medium text-left">
          {formatMessage({ id: 'decentralize.description' })}
          <span tw="font-semibold">
            {formatMessage({ id: 'decentralize.description2' })}
          </span>
          {formatMessage({ id: 'decentralize.description3' })}
          <span tw="font-semibold">
            {formatMessage({ id: 'decentralize.description4' })}
          </span>
          {formatMessage({ id: 'decentralize.description5' })}
        </Description>
      </div>{' '}
      <BTCP2P tw="w-full mb-10 lg:mb-0 max-width[400px] max-height[400px] lg:(max-width[312px]) xl:(max-width[512px])" />
    </Container>
  );
};
