import { HeroHeaderICO } from '@ui';
import { useIntl } from 'gatsby-plugin-react-intl';
import phone from '../images/ico/phonehero.png';
import tw, { styled } from 'twin.macro';
import Layout from '../components/Layout';
import { Button } from '@ui';
import { NavigationIco } from '../components/navigation-ico';
import { RoadMap } from '../components/Roadmap';
import { Decentralize } from '../components/decentralize';
import { ProductHeader } from '../components/product-header';
import { Auctions } from '../components/auctions';
import { TokenDistribution } from '../components/token-distribution';
import { Whitepaper } from '../components/whitepaper';
import { Team } from '../components/team';
import { RoadmapMobile } from '../components/roadmap-mobile';
import { SEO } from '../components/Seo';
import { PlayCircle, PolygonBadge } from '../styles/icons';
import { Footer } from '../components/footer';
import { Tokenomics } from '../components/tokenomics';
import { YoutubeModal } from '../components/youtube-modal';
import { SocialBar } from '../components/social-bar';
import { useEffect, useState } from 'react';
import { Reservations } from '../components/reservations';
import { Invest } from '../components/invest';
import { UseOfToken } from '../components/use-of-token';
import { AuctionFee } from '../components/auction-fee';

const H1 = styled.h1`
  line-height: 70px;
  ${tw`text-5xl font-bold text-center text-black mt-10`}
  ${tw`md:text-6xl`}
  ${tw`lg:text-left lg:text-5xl`}
  ${tw`xl:text-7xl`}
`;

const P = styled.p`
  ${tw`pt-6 text-lg font-normal text-black self-center`}
  ${tw`text-center`}
  ${tw`lg:(text-left self-start text-xl max-width[512px])`}
`;

const Wrapper = styled.main`
  ${tw`container mx-auto z-0 lg:z-10`}
`;

const GraySection = styled.div`
  ${tw`relative mt-20 lg:mt--7 z-0`}
  background: rgba(250, 250, 252, 1);
`;

const DarkSection = styled.div`
  ${tw`relative lg:mt-20 lg:mt--7 z-0`}
  border-top: 1px solid rgba(0, 19, 38, 0.1);
  border-bottom: 1px solid rgba(0, 19, 38, 0.1);
  background: #243444;
`;

const CircleContainer = styled.div`
  ${tw`absolute flex items-center justify-center transform top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] bg-white rounded-full`}
  max-width: 80px;
  max-height: 80px;
  box-shadow: 0px 16px 64px rgba(0, 5, 51, 0.2);
  backdrop-filter: blur(20px);
`;

function Index({ location }) {
  const [showModal, setShowModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const { formatMessage, locale } = useIntl();

  const t = (key: string) => formatMessage({ id: key });

  const toggleVisibility = () => {
    const scroll = window.scrollY;
    if (scroll > 800) {
      setVisible(true);
    } else if (scroll <= 800) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <Layout tw="font-body flex flex-col">
      <SEO description={t('seo.description')} location={location} />
      <NavigationIco location={location} />

      <HeroHeaderICO tw="flex items-center pt-40 z-20">
        <div tw="container mx-auto flex flex-col justify-center px-5 z-10">
          <div tw="flex lg:flex-row flex-col xl:justify-between">
            <div tw="w-full flex flex-col">
              <div tw="flex flex-col lg:flex-row justify-between">
                <div tw="flex flex-col lg:items-start">
                  {locale === 'pl' ? (
                    <H1>
                      {t('hero.mainICO1')}
                      <br />
                      <span tw="text-grey">{t('hero.mainICO2')}</span>.
                    </H1>
                  ) : (
                    <H1>
                      {t('hero.mainICO1')} <br tw="sm:hidden" />
                      {t('hero.mainICO2').split(' ')[1]}{' '}
                      <br tw="hidden sm:block" />
                      {t('hero.mainICO2').split(' ')[2]}
                      <br tw="sm:hidden" />
                      <span tw="text-grey"> {t('hero.mainICO3')}</span>
                      {'.'}
                    </H1>
                  )}
                  <P>
                    {t('hero.subICO')}(<span tw="font-semibold">NFT</span>).
                  </P>

                  <a
                    href={
                      'https://www.canva.com/design/DAErYZUYwow/FO4Pzhzjv39q0Cc1QX3oNg/view?utm_content=DAErYZUYwow&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton'
                    }
                    target="_blank"
                    rel="noreferrer"
                    id="click-gtag-whitepaper-whitepaper"
                  >
                    <Button
                      tw="mt-14 mx-auto md:w-60 lg:(mt-10 mx-0) rounded-full bg-primary text-white hover:opacity-80"
                      primary
                    >
                      {formatMessage({ id: 'hero.button' })}
                    </Button>
                  </a>

                  <div tw="flex items-center flex-col mt-28 lg:(flex-row mt-32)">
                    {locale === 'pl' ? (
                      <SocialBar tw="max-width[327px] mb-5 lg:(mr-8 mb-0)" />
                    ) : (
                      <SocialBar tw="max-width[297px] mb-5 lg:(mr-8 mb-0)" />
                    )}
                    <p tw="mb-4 lg:(mr-8 mb-0) text-grey">
                      {formatMessage({ id: 'hero.or' })}
                    </p>
                    <a
                      tw="underline hover:opacity-70"
                      href="mailto:hello@bidda.io"
                    >
                      {formatMessage({ id: 'hero.contact' })}
                    </a>
                  </div>
                </div>

                <div
                  tw="relative mx-auto lg:mr--6 mt-20 lg:(mt--2 mr-2) xl:(mt-5 mr-0)"
                  style={{
                    filter:
                      'drop-shadow(-8.2963px 33.1852px 99.5556px rgba(0, 0, 0, 0.15))',
                  }}
                >
                  <YoutubeModal
                    onClose={() => setShowModal(false)}
                    show={showModal}
                  />
                  <img
                    src={phone}
                    alt="app phone"
                    tw="max-width[235px] max-height[486px] lg:(max-width[316px] max-height[636px]) xl:(max-width[336px] max-height[690px])"
                  />
                  <CircleContainer>
                    <a
                      href={`${
                        locale === 'pl'
                          ? 'https://www.youtube.com/watch?v=W-WjK2Gsf3E'
                          : 'https://www.youtube.com/watch?v=1a3xFsuXhL8'
                      }`}
                      target="blank"
                      tw="lg:hidden"
                    >
                      <PlayCircle tw="cursor-pointer hover:opacity-80" />
                    </a>
                    <PlayCircle
                      tw="hidden lg:flex cursor-pointer hover:opacity-80"
                      onClick={() => {
                        setShowModal(true);
                        document.body.classList.add('stop-scrolling');
                      }}
                    />
                  </CircleContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeroHeaderICO>
      <GraySection>
        <Wrapper>
          <div tw="flex justify-start mt-8 pl-5 lg:pl-10 xl:pl-0">
            <a
              href="https://polygon.technology/"
              target="_blank"
              rel="noreferrer"
            >
              <PolygonBadge tw="w-40 pl-5" />
            </a>
          </div>

          <Decentralize />
          <ProductHeader />
        </Wrapper>
        <Auctions />
        <Reservations />
        <Invest />
      </GraySection>
      <Tokenomics />
      <DarkSection>
        <Wrapper tw="px-5">
          <UseOfToken />
          <AuctionFee />
          <TokenDistribution />
        </Wrapper>
      </DarkSection>

      <div tw="relative -top-28" id="whitepaper"></div>
      <Whitepaper />
      <div tw="relative lg:mt-40">
        <div tw="absolute top--40" id="roadmap"></div>
        <RoadmapMobile />
        <div tw="hidden lg:block">
          {' '}
          <RoadMap />
        </div>
      </div>
      <Team />
      <div
        style={{
          opacity: visible ? '1' : '0',
          transition: 'all 0.125s ease-in',
        }}
        id="social-bar"
        tw="sticky bottom-8 mx-auto md:flex mt-80 z-40"
      >
        <SocialBar />
      </div>

      <div tw="bg-dark px-5 mt-8">
        <Footer />
      </div>
    </Layout>
  );
}

export default Index;
