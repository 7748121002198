import tw, { styled } from 'twin.macro';
import { AuctionFeeSVG } from '../styles/icons';
import { DSDescription, DSTitle } from '../styles/shared-styles';
import { useIntl } from 'gatsby-plugin-react-intl';

const Container = styled.div`
  ${tw`relative flex flex-col lg:(flex-row items-start) items-center justify-between py-20 md:py-40 border-bottom-width[1px] border-bottom-color[rgba(255, 255, 255, 0.15)]`}
`;

export const AuctionFee = () => {
  const { formatMessage } = useIntl();

  return (
    <Container>
      <div tw="flex flex-col lg:items-start">
        <DSTitle tw="max-width[336px] mb-4">
          {formatMessage({ id: 'auctionFee.title' })}
        </DSTitle>
        <DSDescription tw="max-width[421px] text-left">
          {formatMessage({ id: 'auctionFee.description' })}
        </DSDescription>
        <DSDescription tw="font-semibold max-width[421px] mt-4 text-left">
          {formatMessage({ id: 'auctionFee.descriptionBold' })}
        </DSDescription>
        <div tw="background[#FFFFFF1A] w-full p-6 pl-10 max-width[512px] rounded-2xl mt-6">
          <ul tw="list-disc text-white font-semibold text-left">
            <li>{formatMessage({ id: 'auctionFee.point1' })}</li>
            <li>{formatMessage({ id: 'auctionFee.point2' })}</li>
            <li>{formatMessage({ id: 'auctionFee.point3' })}</li>
            <li>{formatMessage({ id: 'auctionFee.point4' })}</li>
          </ul>
        </div>
      </div>
      <div tw="w-full flex flex-shrink justify-end mt-20 lg:mt-0">
        <AuctionFeeSVG tw="w-full mx-auto lg:mx-0 h-full max-width[688px]" />
      </div>
    </Container>
  );
};
