import tw, { css, styled, theme } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Milestone } from './Roadmap';
import { QUARTERS } from '../content';
import { useState } from 'react';
import { Button } from '@ui';

const Container = styled.div`
  ${tw`flex flex-col lg:hidden w-full pb-16 border-t border-b border-gray-200`}
  background: #fafafc;
`;

const IdeaBorn = styled.div`
  ${tw`flex flex-row items-center justify-between w-auto h-16 text-white px-5 mx-5 my-16 mb-5`}
  background: rgba(0, 19, 38, 0.85);
  box-shadow: 0px 40px 80px rgba(0, 21, 89, 0.2);
  border-radius: 12px;
`;

const P = styled.p`
  ${tw`text-lg font-bold uppercase`}
`;

const Quarter = ({ milestones, title, ...props }) => {
  return (
    <div tw="flex flex-col w-full" {...props}>
      <div tw="flex flex-col w-full pt-5 pb-5">
        <div tw="flex flex-row justify-center text-base items-center">
          <div>{title.split(' ')[0]}</div>
          <div tw="pl-1">{title.split(' ')[1]}</div>
        </div>
        <div tw="w-full h-1 border-t-2 px-5"></div>
      </div>
      <div tw="flex flex-col">
        <div tw="flex flex-col">
          {milestones.map((item, idx) => (
            <Milestone
              special={item.special}
              title={item.title}
              date={item.date}
              dot={item.dot}
              order={item.level}
              inactive={item.inactive}
              border={item.border}
              bg={item.bg}
              btn={item.btn}
              key={idx}
              tw="opacity-100 transform translate-x-0"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const RoadmapMobile = () => {
  const [flag, setFlag] = useState(false);
  const { formatMessage } = useIntl();

  const hideRoadMap = () => {
    setFlag(!flag);
    flag &&
      document
        .getElementById('scroll-to-tile')
        .scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Container>
      <h1 tw="text-5xl md:text-7xl text-grey opacity-40 text-center font-bold pt-14">
        {formatMessage({ id: 'roadmap.h2' })}
      </h1>
      <IdeaBorn>
        <P>{formatMessage({ id: 'roadmap.dates.genesis.title' })}</P>
        <P tw="font-normal normal-case">
          {formatMessage({ id: 'roadmap.dates.genesis.date' })}
        </P>
      </IdeaBorn>
      <div tw="flex flex-col w-full justify-between px-5">
        {QUARTERS.slice(0, 4).map((item, idx) =>
          item.items.length ? (
            <Quarter
              title={item.title}
              milestones={item.items}
              key={idx}
              id={idx === item.items.length ? 'scroll-to-tile' : null}
            />
          ) : null
        )}
      </div>
      {flag ? (
        <div tw="flex flex-col w-full justify-between px-5">
          {QUARTERS.slice(4).map((item, idx) =>
            item.items.length ? (
              <Quarter title={item.title} milestones={item.items} key={idx} />
            ) : null
          )}
        </div>
      ) : null}
      <div tw="flex w-full justify-center pt-10 pb-6 px-5">
        <Button
          tw="mt-5 md:mt-0 w-full md:w-60 bg-light-blue text-primary hover:opacity-80 px-5"
          primary
          block
          onClick={() => {
            hideRoadMap();
          }}
        >
          {flag
            ? formatMessage({ id: 'roadmap.hideBtn' })
            : formatMessage({ id: 'roadmap.showBtn' })}
        </Button>
      </div>
    </Container>
  );
};
