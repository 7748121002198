import { WhitepaperSvg } from '../styles/icons';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Description, SubtitleICO, TitleICO } from '../styles/shared-styles';
import { Button } from '@ui';

const Container = styled.div`
  ${tw`bg-[#364453] `}

  width: 100%;
`;

export const Whitepaper = () => {
  const { formatMessage, locale } = useIntl();

  const WhitepaperEN = '../../Whitepaper-EN.pdf';
  const WhitepaperPL = '../../Whitepaper-PL.pdf';

  return (
    <Container>
      <div tw="container text-center relative flex p-5 md:(mb-20 mt-20) mx-auto flex-col items-center lg:(text-left flex-row justify-between)">
        <div tw="order-2 lg:(w-1/2 order-1) xl:w-5/12">
          <TitleICO tw="text-white">
            {formatMessage({ id: 'whitepaper.title' })}
          </TitleICO>
          {locale === 'pl' ? (
            <SubtitleICO tw="text-white w-full lg:w-11/12">
              {formatMessage({ id: 'whitepaper.subtitle1' })}
              <span tw="text-blue">
                {formatMessage({ id: 'whitepaper.subtitle2' })}
              </span>
            </SubtitleICO>
          ) : (
            <SubtitleICO tw="text-blue w-full">
              {formatMessage({ id: 'whitepaper.subtitle1' })}
              <br />
              <span tw="text-white">
                {formatMessage({ id: 'whitepaper.subtitle2' })}
              </span>
            </SubtitleICO>
          )}
          <Description tw="text-white">
            {formatMessage({ id: 'whitepaper.description' })}
          </Description>
          <div tw="pt-14 mb-20 md:(flex flex-row justify-center mb-0 pt-24) lg:(justify-start flex flex-col) xl:flex-row">
            <a
              href={locale === 'pl' ? WhitepaperPL : WhitepaperEN}
              target="_blank"
              rel="noreferrer"
              download
              id="click-gtag-whitepaper-whitepaper"
            >
              <Button
                tw="mt-5 md:(mt-0 w-64) lg:mt-5 xl:mt-0 rounded-full bg-blue text-black font-weight[500] hover:opacity-80"
                primary
              >
                {formatMessage({ id: 'whitepaper.button2' })}
              </Button>
            </a>
          </div>
        </div>
        <div
          tw="flex justify-center self-center mt-20 md:mt-0 w-full pb-10 order-1 lg:(order-2 mt-28)"
          style={{ maxWidth: 512 }}
        >
          <WhitepaperSvg tw="width[300px] mb-10 h-full lg:w-96 xl:w-auto" />
        </div>
      </div>
    </Container>
  );
};
